import Button from "../../../customComponents/Button";

const CandidateCard = ({
  name,
  image,
  id,
  setSelectedCandidate,
  submitVotes,
  loading,
  candidateCount,
}) => {

  

  return (
    <div className="col candidate-card">
      <label className="custom-checkbox">
        <input
          type="checkbox"
          name="checkbox"
          // checked={checked}
          value={id}
        />
        <div className="checkbox-btn">
          <div className="content">
            <div className="text-center">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/${image}`}
                width="70%"
                alt="CandidateImage"
              />
            </div>
            <p className="mt-0 text-white text-center">{name}</p>
            <Button
              buttonText={"Cast Vote (Yes)"}
              cssClasses={"btn btn-success  w-100 mt-0 mb-4 text-nowrap"}
              isloading={loading}
              callback={(e) => {
                setSelectedCandidate(id);
                submitVotes(e, "yes",id);
              }}
            />
            {candidateCount ===1 && (
              <Button
                buttonText={"Deny(N0)"}
                cssClasses={"btn btn-danger  w-100 mt-0 mb-4 text-nowrap"}
                isloading={loading}
                callback={(e) => {
                  setSelectedCandidate(id);
                  submitVotes(e, "no",id);
                }}
              />
            )}
            <span className="check-icon">
              <span className="icon"></span>
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default CandidateCard;
