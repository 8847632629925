import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "../../context/ApiContext";

function Polls() {
  const navigate = useNavigate();
  const { GetRequest } = useApiContext();
  const [polls, setPolls] = useState([]);

  useEffect(() => {
    async function getPolls() {
      const response = await GetRequest("/api/poll/all");
      if (response?.status === 200) {
        setPolls(response?.data);
      }
    }
    getPolls();
  }, [GetRequest]);
  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container center-items col-lg-12">
        <div className="card card-shadow">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <h3>Poll Setup</h3>
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/dashboard/poll/add")}
              >
                Add New Poll
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>State </th>
                  </tr>
                </thead>
                <tbody>
                  {polls?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-capitalize">{item?.title}</td>
                      <td>{item?.start_date}</td>
                      <td>{item?.end_date}</td>
                      <td>{item?.state}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Polls;
