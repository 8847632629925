/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "../../context/ApiContext";
import Button from "../../customComponents/Button";
import Swal from "sweetalert2";

function Candidates() {
  const [isDeleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const { GetRequest } = useApiContext();
  const [candidates, setCandidates] = useState([]);
  const [polls, setPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(0);

  useEffect(() => {
    async function getPolls() {
      const response = await GetRequest("/api/poll/all");
      if (response?.status === 200) {
        setPolls(response?.data);
      }
    }
    getPolls();
  }, []);

  const { PostRequest } = useApiContext();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function getCandidates() {
      const response = await GetRequest(
        `/api/candidate/poll/${selectedPoll}/position/all`
      );
      if (response?.status === 200) {
        setCandidates(response?.data);
        localStorage.setItem("poll_id", selectedPoll);
      }
    }
    if (selectedPoll !== 0) {
      getCandidates();
    }
  }, [selectedPoll, reload]);

  const handleDelete = (e, id) => {
    e.preventDefault();

    setDeleting(true);
    Swal.fire({
      title: "Question",
      text: "Are you sure to delete candidate?",
      icon: "question",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
    })
      .then((response) => {
        if (response.isConfirmed) {
          PostRequest(`/api/candidate/${id}/delete`);
          setReload(!reload);
        }
      })
      .finally((_) => setDeleting(false));
    setDeleting(false);
  };

  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container center-items col-lg-12">
        <div className="card card-shadow">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <h3>Manage Candidates</h3>
              <div className="w-25">
                <select
                  className="form-control"
                  value={selectedPoll}
                  onChange={(e) => setSelectedPoll(e.target.value)}
                >
                  <option value={0}>Select Poll</option>
                  {polls?.map((poll, index) => (
                    <option key={index} value={poll?.id}>
                      {poll?.title}
                    </option>
                  ))}
                </select>
              </div>
              {selectedPoll !== 0 && (
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate("/dashboard/candidate/create")}
                >
                  Add New Candidate
                </button>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="table-responive w-100">
              {selectedPoll === 0 ? (
                <div className="text-center mt-4">
                  <h4>Select Poll To Load Candidates</h4>
                </div>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Position</th>
                      <th>Position of Ballot</th>
                      <th>State </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidates?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-capitalize">{item?.name}</td>
                        <td>{item?.position?.title}</td>
                        <td>{item?.ballot_position}</td>
                        <td>{item?.state}</td>
                        <td>
                          <Button
                            buttonText={"Delete"}
                            isloading={isDeleting}
                            callback={(e) => handleDelete(e, item?.id)}
                            cssClasses={"btn btn-danger btn-sm"}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Candidates;
