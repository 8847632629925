import React, { useEffect, useState } from "react";
import Button from "../../customComponents/Button";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

function VerificationForm({
  userInput,
  handleUserInput,
  setVoter,
  setPositions,
  setUserInput,
  setScreen,
}) {
  useEffect(() => {
    localStorage.removeItem("code");
    localStorage.removeItem("category_id");
    localStorage.removeItem("membership_no");
    localStorage.removeItem("name");
  }, []);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      verification_code: userInput.verification_code,
    };
    axios
      .post("/api/voters/verification", data)
      .then((res) => {
        console.log(res?.data);

        if (res?.status === 200) {
          setVoter(res?.data?.data?.voter);
          setPositions(res?.data?.data?.positions);
          setScreen("vote");
        }
      })
      .catch((err) => {
        console.log(err?.response);
        if (err.response.data.status === 400) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err.response?.data?.error_msg,
          }));
        }

        if (err.response.status === 422) {
          Swal.fire("Info", "Invalid Verification Code Entered", "warning");
        }
      })
      .finally((_) => setLoading(false));
  };
  return (
    <div
      style={{
        backgroundImage:
          "url('https://th.bing.com/th/id/R.a36f8c03d0686d9e224f4c98ae3cb009?rik=FERf3soZDPIxvA&pid=ImgRaw&r=0')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div className="container" style={{ paddingTop: "40vh" }}>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="row">
              <form
                action="form-inline"
                className="w-100 text-center"
                onSubmit={handleSubmit}
              >
                <div className="form-group text-center p-3">
                  <input
                    type="text"
                    placeholder="Enter Verification Code Here.."
                    className={`form-control text-center w-100 ${
                      userInput?.errors ? "is-invalid" : ""
                    }`}
                    maxLength={10}
                    autoComplete="off"
                    name="verification_code"
                    value={userInput?.verification_code}
                    onChange={handleUserInput}
                    required
                  />
                  <div className="text-danger bg-dark">{userInput?.errors}</div>
                </div>
                <Button
                  isloading={loading}
                  buttonText={"Verify"}
                  cssClasses={
                    "btn btn-primary form-control text-white w-50 mt-2"
                  }
                />
                <p>
                  <Link to={"/login"}>Login as Admin</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerificationForm;
