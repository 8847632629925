import Header from "./partials/Header"
import Sidebar from "./partials/Sidebar"
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';


const DashboardLayout=(props)=>{
    const navigate=useNavigate()
    useEffect(()=>{
        if(!localStorage.getItem('token')){
            navigate('/login');
        }
    },[navigate])


    return(
        <div className="main-wrapper">
            <Header/>
            <Sidebar/>
            <Outlet/>
        </div>
    )
}

export default DashboardLayout