import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Button from "../../customComponents/Button";

const CreateCandidate = () => {
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/position/poll/${localStorage.getItem("poll_id")}/all`)
      .then((res) => {
        setPositions(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, []);

  const [image, setImage] = useState("");
  const [userInput, setUserInput] = useState({
    name: "",
    position_id: "",
    ballot_position: "",
    state: "active",
    errors: {},
  });

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
    setUserInput((userInput) => ({
      ...userInput,
      errors: {},
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let fd = new FormData();
    fd.append("name", userInput?.name);
    fd.append("poll_id", localStorage.getItem("poll_id"));
    fd.append("position_id", Number(userInput?.position_id));
    fd.append("image", image);
    fd.append("ballot_position", userInput?.ballot_position);

    axios
      .post("/api/candidate/create", fd)
      .then((res) => {
        if (res.data.status === 201) {
          swal("Success", "Candidate Registration Successful", "success");
          setUserInput((userInput) => ({
            ...userInput,
            name: "",
            position_id: "",
            ballot_position: "",
            state: "active",
            errors: {},
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        setUserInput((userInput) => ({
          ...userInput,
          errors: err.response.data.err_msg,
        }));
      })
      .finally((_) => setLoading(false));
  };

  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container center-items col-lg-8">
        <div className="card card-width card-shadow">
          <div className="card-header">
            <h3>Candidate Registration</h3>
          </div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="row formtype">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Name</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={userInput?.name}
                              onChange={handleUserInput}
                            />
                          </div>
                          <div className="text-danger">
                            {userInput?.errors?.name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="form-group">
                          <label>Position</label>
                          <select
                            className="form-control dropdown"
                            id="sel2"
                            name="position_id"
                            value={userInput?.position_id}
                            onChange={handleUserInput}
                          >
                            <option value="">Select</option>
                            {positions.map((position) => (
                              <option key={position.id} value={position.id}>
                                {position.title}
                              </option>
                            ))}
                          </select>
                          <div className="text-danger">
                            {userInput?.errors?.position_id}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Position on Ballot</label>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              name="ballot_position"
                              value={userInput?.ballot_position}
                              onChange={handleUserInput}
                              min={1}
                            />
                          </div>
                          <div className="text-danger">
                            {userInput?.errors?.ballot_position}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="form-group">
                          <label>Upload Image</label>
                          <div className="custom-file mb-3">
                            <input
                              type="file"
                              className="form-control"
                              id="customFile"
                              name="image"
                              onChange={(e) => handleImageUpload(e)}
                            />
                          </div>
                          <div className="text-danger">
                            {userInput?.errors?.image}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button
                      cssClasses={"btn btn-primary text-white form-control"}
                      isloading={loading}
                      buttonText={"Save"}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCandidate;
