import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const ApiContext = createContext({
  GetRequest: () => {},
  PostRequest: () => {},
});

export const ApiContextProvider = ({ children }) => {
  const [response, setResponse] = useState("");
  const navigate = useNavigate();

  const PostRequest = async (url, apidata) => {
    try {
      const res = await axios.post(url, apidata);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: res.data.message || "Action successful",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return res?.data;
    } catch (error) {
      const status = error.response.status;

      if (status === 400) {
        setResponse("Action Failed");
      } else if (status === 403 || status === 405) {
        setResponse("Forbidden");
      } else if (status === 401) {
        setResponse("User authentication Failed");
        navigate('/login');
      } else if (status === 422) {
        setResponse("Validation Error");
      } else if (status === 404) {
        setResponse("Record not found");
      } else {
        setResponse("An error occurred");
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        text: response,
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return error?.response;
    }
  };

  const GetRequest = async (url, apidata) => {
    try {
      const res = await axios.get(url, { data: apidata });
      setResponse(res.data.message || "Success");
      return res.data;
    } catch (error) {
      const status = error.response.status;

      if (status === 400) {
        setResponse("Failed to update school");
      } else if (status === 403 || status === 405) {
        setResponse("Forbidden");
      } else if (status === 401) {
        setResponse("User authentication Failed");
        navigate('/login');
      } else if (status === 422) {
        setResponse("Validation Error");
      } else if (status === 404) {
        setResponse("Record not found");
      } else {
        setResponse("An error occurred");
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        text: response,
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return error?.response;
    }
  };

  const values = {
    PostRequest,
    GetRequest,
  };

  return <ApiContext.Provider value={values}>{children}</ApiContext.Provider>;
};

export const useApiContext = () => {
  const { PostRequest, GetRequest } = useContext(ApiContext);
  return { PostRequest, GetRequest };
};
