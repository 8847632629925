import Dashboard from "./components/Pages/Dashboard";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import CreateCandidate from "./components/Pages/candidates/CreateCandidate";
import Voters from "./components/Pages/Voters";
import Verification from "./components/Pages/voter/Verification";
import Vote from "./components/Pages/voter/Vote";
import Results from "./components/Pages/Results";
import Login from "./components/Pages/Login";
import Polls from "./components/Pages/poll/Polls";
import AddPoll from "./components/Pages/poll/AddPoll";
import { ApiContextProvider } from "./components/context/ApiContext";
import Candidates from "./components/Pages/candidates/Candidates";
import Positions from "./components/Pages/positons/Positions";
import AddPosition from "./components/Pages/positons/AddPosition";

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.baseURL = `https://smartcast.farmeraidgh.com`;

axios.defaults.baseURL=process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  return (
    <ApiContextProvider>
      <Routes>
        <Route path="/" element={<Verification />} />
        <Route path="/vote" element={<Vote />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="/dashboard/poll" element={<Polls />} />
          <Route path="/dashboard/positions" element={<Positions />} />
          <Route
            path="/dashboard/poll/:id/position/create"
            element={<AddPosition />}
          />
          <Route path="/dashboard/poll/add" element={<AddPoll />} />
          <Route path="/dashboard/candidates" element={<Candidates />} />
          <Route
            path="/dashboard/candidate/create"
            element={<CreateCandidate />}
          />
          <Route path="/dashboard/voters" element={<Voters />} />
          <Route path="/dashboard/results" element={<Results />} />
        </Route>
      </Routes>
    </ApiContextProvider>
  );
}

export default App;
