import CandidateCard from "./voteComponents/CandidateCard";
import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Button from "../../customComponents/Button";

const Vote = ({ voter, positions, setVoter, setPositions, setScreen }) => {
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitVotes = (e, action, id = null) => {
    e.preventDefault();
    if (id !== null || selectedCandidate !== id) {
      setSelectedCandidate(id);
    }
    if (selectedCandidate === null && action !== "skipped") {
      window.alert("Please Try again");
      return;
    }

    setLoading(true);
    const data = {
      verification_code: voter?.verification_code,
      poll_id: voter?.poll_id,
      voter_id: voter?.id,
      action: action,
      candidate_id: action === "skipped" ? null : id,
      position_id: positions[0]["id"],
    };

    axios
      .post("/api/voters/vote", data)
      .then((res) => {
        if (res.data.status === 200) {
          if (res?.data?.data?.positions?.length === 0) {
            Swal.fire(
              "Succes",
              "Voting has been completed successfully",
              "success"
            ).then((_) => {
              setScreen("verification");
              window.location.reload();
            });
          }
          setVoter(res?.data?.data?.voter);
          setPositions(res?.data?.data?.positions);
        }
      })
      .catch((err) => {
        if (err.response.data.status === 400) {
          swal({
            title: "Error",
            text: "Something Went Wrong, Please Verify and Try again",
            icon: "error",
            dangerMode: true,
          }).then(() => {
            setScreen("verification");
          });
        }
      })
      .finally((_) => setLoading(false));
  };

  return (
    <div>
      <div className="container-fuild">
        <div className="row mb-3 d-flex justify-content-center align-items-center">
          <h3 className="text-uppercase text-center">
            {positions[0]["title"]}
          </h3>
          <Button
            buttonText={"Skipp This Portfolio"}
            cssClasses={"btn btn-info  w-100 mt-0 mb-4 text-nowrap"}
            isloading={loading}
            callback={(e) => submitVotes(e, "skipped")}
          />
        </div>
        <div className="">
          <div className="row align-items-center justify-content-center">
            {positions?.[0]?.["candidates"].map((candidate) => (
              <CandidateCard
                key={candidate.id}
                name={candidate.name}
                image={candidate.image}
                id={candidate.id}
                setSelectedCandidate={setSelectedCandidate}
                loading={loading}
                candidateCount={positions?.[0]?.["candidates"]?.length}
                submitVotes={submitVotes}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vote;
