import { useState } from "react";
import VerificationForm from "./VerificationForm";
import Vote from "./Vote";

const Verification = () => {
  const [voter, setVoter] = useState([]);
  const [positions, setPositions] = useState([]);
  const [screen, setScreen] = useState("verification");
  const [userInput, setUserInput] = useState({
    verification_code: "",
    errors: '',
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      errors: '',
    }));
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      {screen === "verification" && (
        <VerificationForm
          userInput={userInput}
          handleUserInput={handleUserInput}
          setVoter={setVoter}
          setPositions={setPositions}
          setUserInput={setUserInput}
          setScreen={setScreen}
        />
      )}
      {screen === "vote" && (
        <Vote
          positions={positions}
          voter={voter}
          setScreen={setScreen}
          setVoter={setVoter}
          setPositions={setPositions}
        />
      )}
    </>
  );
};

export default Verification;
