import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js/auto";

Chart.register(...registerables);
const ResultsChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (data) {
      setChartData({
        labels: data?.map((item) => item?.name),
        datasets: [
          {
            label: "Vote Count",
            data: data?.map((item) => item?.vote_count?.yes_votes),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data]);

  return (
    <div>
      <BarChart
        data={chartData}
        options={{
          Animation: true,
        }}
      />
    </div>
  );
};

const BarChart = ({ data }) => {
  if (data.labels.length === 0 || data.datasets.length === 0) {
    return null;
  }
  return <Bar data={data} />;
};

export default ResultsChart;
