import { useState,useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { useApiContext } from "../context/ApiContext";
import Button from "../customComponents/Button";


const Login = () => {
  const {PostRequest}=useApiContext();
  const [loading,setLoading]=useState(false);
    const navigate=useNavigate();
    const [userInput, setUserInput]=useState({
        email:'',
        password:'',
        error:{}
    })

    const handleUserInput=(e)=>{
        setUserInput(userInput=>({
            ...userInput,[e.target.name]:e.target.value
        }))
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        setLoading(true);
        const data={
            email:userInput?.email,
            password:userInput?.password,
        }

        const response = await PostRequest("/api/auth/login", data);
        console.log(response);
        if(response?.status===200){
          localStorage.setItem("token", response?.data?.token);
            localStorage.setItem("role", response?.data?.user?.id);
            localStorage.setItem("name", response?.data?.user?.name);
            navigate("/dashboard");
        }
    
        if (response?.status === 401 || response?.status === 400) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: response?.data?.error_msg || response?.error_msg,
          }));
        }
        setLoading(false);
    }

    useEffect(()=>{
        localStorage.clear();
    },[navigate])
  return (
    <div className="log-box form1">
      <div className="row">
        <div className="col-lg-8">
          <form className="login-box1" onSubmit={handleSubmit}>
            <div className="input-box1">
              <label htmlFor="">Email *</label>
              <input
                type="text"
                name="email"
                onChange={handleUserInput}
                value={userInput.email}
                required
                className="create_form form-control"
              />
              <div className="text-danger">{userInput?.error?.email}</div>
            </div>
            <div className="input-box1">
              <label htmlFor="">Password *</label>
              <input
                type="password"
                name="password"
                onChange={handleUserInput}
                required
                value={userInput.password}
                className="create_form form-control"
              />
                <div className="text-danger">{userInput?.error?.password}</div>
            </div>
            <div className="d-block">
              <div className="forgot mb-2">
                <Button isloading={loading} buttonText={'Login'} cssClasses={'btn btn-dark'}/>
              </div>
              <div>
              
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
};

export default Login;
