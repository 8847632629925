import { Link,useNavigate } from "react-router-dom"


const Sidebar=()=>{
	const navigate=useNavigate();
    return(
		<div id="noprint">
			<div className="sidebar" id="sidebar">
				<div className="sidebar-inner slimscroll">
					<div id="sidebar-menu" className="sidebar-menu">
						<ul id="noprint">
							<li> <Link to="/dashboard"><i className="fas fa-tachometer-alt"></i> <span>Dashboard</span></Link> </li>
							<li> <Link to="/dashboard/poll"><i className="fas fa-table"></i> <span>Manage Polls</span></Link> </li>
							<li> <Link to="/dashboard/positions"><i className="fas fa-table"></i> <span>Manage Positions</span></Link> </li>
							<li> <Link to="/dashboard/candidates"><i className="far fa-user"></i> <span>Manage Candidate</span></Link> </li>
							<li> <Link to="/dashboard/voters"><i className="far fa-thumbs-up"></i> <span>Manage Voters</span></Link> </li>
							<li> <Link to="/dashboard/results"><i className="far fa-chart-bar"></i> <span>Results</span></Link> </li>
							<li onClick={()=>{
								localStorage.clear('token');
								navigate('/login')
							}}> <Link to="#"><i className="fas fa-sign-out-alt"></i> <span>Sign Out</span></Link> </li>
						</ul>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Sidebar