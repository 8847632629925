import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useApiContext } from "../context/ApiContext";
import ResultsChart from "./Chart";

const Results = () => {
  const [results, setResults] = useState([]);
  const [positions, setPositions] = useState([]);
  const [candidatesCount, setCandidateCount] = useState(0);
  const tableRef = useRef();
  const { GetRequest } = useApiContext();
  const [selectedPoll, setSelectedPolls] = useState("0");
  const [selectedPosition, setSelectedPosition] = useState("0");
  const [polls, setPolls] = useState();

  useEffect(() => {
    async function getPolls() {
      const response = await GetRequest("/api/poll/all");
      if (response?.status === 200) {
        setPolls(response?.data);
      }
    }
    getPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPoll !== "0") {
      axios.get(`/api/position/poll/${selectedPoll}/all`).then((res) => {
        if (res.data.status === 200) {
          setPositions(res.data.data);
        }
      });
    }
  }, [selectedPoll]);

  useEffect(() => {
    if (selectedPoll !== "0" && selectedPosition !== "0") {
      axios
        .get(
          `/api/v1/poll/${selectedPoll}/position/${selectedPosition}/results`
        )
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            setResults(res.data?.data);
            setCandidateCount(res?.data?.data?.candidates?.length);
          }
        });
    }
  }, [selectedPosition, selectedPoll]);

  let validVoteCast = 0;
  if (results) validVoteCast = results?.total_vote_cast - results?.skipped_vote;

  function calcPercentage(votes) {
    if (validVoteCast > 0) {
      const percentage = (votes / validVoteCast) * 100;
      return percentage.toFixed(2);
    }

    return 0;
  }

  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container-fluid center-items">
        <div className="p-2">
          <h3>Voting Results</h3>
        </div>
        <div className="row">
          <Card title={"Total Voters"} value={results?.total_voters} />
          <Card title={"Total VoteCast"} value={results?.total_vote_cast} />
          <Card title={"Total Vote Skipped"} value={results?.skipped_vote} />
        </div>
        <div className="card card-width card-shadow">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-2">
                <select
                  className="form-control"
                  value={selectedPoll}
                  onChange={(e) => setSelectedPolls(e.target.value)}
                >
                  <option value={0}>Select Poll</option>
                  {polls?.map((poll, index) => (
                    <option key={index} value={poll?.id}>
                      {poll?.title}
                    </option>
                  ))}
                </select>
              </div>

              {selectedPoll !== "0" && (
                <div className="p-2">
                  <select
                    name="postition"
                    className="form-control"
                    value={selectedPosition}
                    onChange={(e) => setSelectedPosition(e.target.value)}
                  >
                    <option value="">Select Position</option>
                    {positions.map((position) => (
                      <option key={position.id} value={position.id}>
                        {position.title}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="p-2">
                Display Top{" "}
                <input
                  type="number"
                  name=""
                  value={candidatesCount}
                  onChange={(e) => setCandidateCount(e.target.value)}
                  id=""
                />
              </div>
            </div>
          </div>
          <div className="card-body" ref={tableRef}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <ResultsChart data={results?.candidates} />
                  </div>
                  <div className="table-responsive mt-5">
                    <table
                      // id="table_id"
                      className="display table-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Image</th>
                          <th>Name</th>
                          <th className="text-center">VoteCount(Yes)</th>
                          {results?.candidates?.length === 1 && (
                            <th className="text-center">VoteCount(No)</th>
                          )}
                          <th className="text-center">Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results?.candidates
                          ?.slice(0, candidatesCount)
                          ?.map((data, index) => (
                            <tr key={index}>
                              <td className="text-center">
                                <img
                                  src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
                                  style={{ width: "30%" }}
                                  className="img-rounded img-responsive mt-2 mb-2"
                                  alt="Candidate"
                                />
                              </td>
                              <td>
                                <h4 className="ms-3">{data?.name}</h4>
                              </td>
                              <td className="text-center">
                                <h2>{`${data?.vote_count?.yes_votes || 0}`}</h2>
                              </td>
                              {results?.candidates?.length === 1 && (
                                <td className="text-center">
                                  <h2>{data?.vote_count?.no_votes || 0}</h2>
                                </td>
                              )}
                              <td className="text-center">
                                <h2>
                                  {calcPercentage(data?.vote_count?.yes_votes)}%
                                </h2>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th className="text-center">Image</th>
                          <th>Name</th>
                          <th className="text-center">VoteCount(Yes)</th>
                          {results?.candidates?.length === 1 && (
                            <th className="text-center">VoteCount(No)</th>
                          )}
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ title, value }) => {
  return (
    <div className="col-md-4">
      <div class="card" style={{ width: "18rem" }}>
        <div class="card-body p-4">
          <h5 class="card-title">{title}</h5>
          <p class="card-text">
            <h2>{value || 0}</h2>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Results;
