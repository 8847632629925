import { useState, useEffect, useRef } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useApiContext } from "../context/ApiContext";
import Button from "../customComponents/Button";

const Voters = () => {
  const datatable = useRef();
  const [uploading, setUploading] = useState(false);
  const [filtered, setFiltered] = useState("");
  const btnGenerate = useRef();
  const [file, setFile] = useState("");
  const [voters, setVoters] = useState([]);
  const [polls, setPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(0);
  const handleFileUplaod = (e) => {
    setFile(e.target.files[0]);
  };

  const [refresh, setRefresh] = useState(false);
  const { GetRequest } = useApiContext();
  useEffect(() => {
    async function getPolls() {
      const response = await GetRequest("/api/poll/all");
      if (response?.status === 200) {
        setPolls(response?.data);
      }
    }
    getPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPoll !== "0") {
      axios
        .get(`/api/voters/poll/${selectedPoll}/all/${filtered}`)
        .then((res) => {
          if (res?.status === 200) {
            setVoters(res.data.data?.data);
          }
        })
        .catch((err) => {
          console.log(err.data.response);
        });
    }
  }, [refresh, filtered, selectedPoll]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedPoll === 0 || selectedPoll === "0") {
      window.alert("Please Select Poll");
    } else {
      let fd = new FormData();
      fd.append("excel_file", file);
      fd.append("poll_id", selectedPoll);
      axios
        .post("/api/voters/upload", fd)
        .then((res) => {
          if (res.data.status === 201) {
            swal(
              "Success",
              "Voters have been imported successfully",
              "success"
            );
            setRefresh(!refresh);
          }
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            swal(
              "Failed",
              "Failed to import voters, make sure excel file has the columns in this form [membership_no,name,gender,phone], also, membership_no should be unique for all records",
              "error"
            );
            console.log(err.response);
            setRefresh(!refresh);
          }
        }).finally(_=>setUploading(false));
    }
  };

  const generateVoterID = (e) => {
    if (selectedPoll === 0 || selectedPoll === "0") {
      window.alert("Please Select Poll");
    } else {
      axios
        .post("/api/voters/generate-id", { poll_id: selectedPoll })
        .then((res) => {
          if (res.data.status === 200) {
            swal(
              "success",
              "New Voter's ID has been generated successfully",
              "success"
            );
            setRefresh(!refresh);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  };

  const records = voters
    .filter((voter) => {
      if (filtered !== "") {
        const lowercasedFilter = filtered.toLowerCase();
        return (
          voter?.membership_no?.toLowerCase()?.includes(lowercasedFilter) ||
          voter?.name?.toLowerCase()?.includes(lowercasedFilter)
        );
      }
      return true;
    })
    .map((voter) => (
      <tr key={voter.id}>
        <td className="text-center">{voter.voter_id}</td>
        <td>{voter.name}</td>
        <td>{voter.gender}</td>
        <td>{voter.verification_code}</td>
        <td></td>
      </tr>
    ));

  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container-fluid center-items">
        <h3>Manage Voters</h3>
        <div className="card card-width card-shadow">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-2">
                <div className="d-flex flex-row justify-content-between">
                  <div className="w-100">
                    <select
                      className="form-control"
                      value={selectedPoll}
                      onChange={(e) => setSelectedPoll(e.target.value)}
                    >
                      <option value={0}>Select Poll</option>
                      {polls?.map((poll, index) => (
                        <option key={index} value={poll?.id}>
                          {poll?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedPoll !== "0" && (
                    <button
                      className="btn btn-primary text-nowrap ml-3"
                      ref={btnGenerate}
                      onClick={generateVoterID}
                    >
                      Generate Voter's ID
                    </button>
                  )}
                </div>
              </div>
              <div className="p-2">
                <form className="form-inline" onSubmit={handleSubmit}>
                  <div className="form-group row">
                    <input
                      type="file"
                      name="file_upload"
                      required
                      className="form-control"
                      onChange={handleFileUplaod}
                    />
                  </div>
                  <div className="form-group row">
                    <Button
                      cssClasses={"btn btn-primary"}
                      isloading={uploading}
                      buttonText={"Save"}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  value={filtered}
                  onChange={(e) => setFiltered(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table
                      // id="table_id"
                      ref={datatable}
                      className="display table-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Association ID</th>
                          <th>Full Name</th>
                          <th>Gender</th>
                          <th>Verification Code</th>
                          <th>Signature</th>
                        </tr>
                      </thead>
                      <tbody>{records}</tbody>
                      <tfoot>
                        <tr>
                          <th className="text-center">Association ID</th>
                          <th>Full Name</th>
                          <th>Gender</th>
                          <th>Verification Code</th>
                          <th>Signature</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voters;
