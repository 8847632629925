import { useState } from "react";
import { useApiContext } from "../../context/ApiContext";
import { useNavigate } from "react-router-dom";
import Button from "../../customComponents/Button";
import Swal from "sweetalert2";

const AddPoll = () => {
  const navigate=useNavigate();
  const [loading,setLoading]=useState(false);
  const {PostRequest}=useApiContext();
  const [userInput, setUserInput] = useState({
    title: "",
    start_date: "",
    end_date: "",
    state: "active",
  });
  const [errors,setErrors]=useState({});

  

  const handleUserInput = (e) => {
   
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    const response = await PostRequest("/api/poll/create", userInput);
   
   console.log(response);
    if(response?.status===201){
      navigate('/dashboard/poll')
    }

    if (response?.status === 401) {
      Swal.fire(response?.data?.error_msg)
    }
    if (response?.status === 422) {
      setErrors(response?.data?.errors)
    }

    setLoading(false);
  };

  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container center-items col-lg-8">
        <div className="card card-width card-shadow">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
            <h3>Poll Setup</h3>
            <button onClick={()=>navigate('/dashboard/poll')} className="btn btn-secondary">Back</button>
            </div>
          </div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form onSubmit={handleSubmit}>
                    <div className="row formtype">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Title</label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              value={userInput.title}
                              onChange={handleUserInput}
                              required
                            />
                            <div className="text-danger">{errors?.title}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="form-group">
                          <label>Start Date</label>
                          <input
                            className="form-control"
                            type="date"
                            name="start_date"
                            value={userInput.start_date}
                            onChange={handleUserInput}
                            required
                          />
                           <div className="text-danger">{errors?.start_date}</div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="form-group">
                          <label>End Date</label>
                          <input
                            className="form-control"
                            type="date"
                            name="end_date"
                            value={userInput.end_date}
                            onChange={handleUserInput}
                            required
                          />
                           <div className="text-danger">{errors?.end_date}</div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            className="form-control"
                            id="sel2"
                            name="state"
                            value={userInput.state}
                            onChange={handleUserInput}
                            required
                          >
                            <option>Select</option>
                            <option value="active">Active</option>
                            <option value="ended">Ended</option>
                          </select>
                          <div className="text-danger">{errors?.state}</div>
                        </div>
                      </div>
                    </div>
                    <Button cssClasses={"btn btn-primary  form-control text-white"} callback={handleSubmit} buttonText={'Save'} isloading={loading}/>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPoll;
