
import { useEffect, useState } from 'react';
import axios from 'axios';

const Cards = () => {
    const [data,setData]=useState([]);
    useEffect(()=>{
        axios.get('/api/v1/totals').then(res=>{
            if(res.data.status===200){
                setData(res.data.data);
                console.log(res.data.data);
            }
        }).catch(err=>{
            console.log(err.response)
        })
    },[])


  return (
    <div className="row">
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card board1 fill padding-card">
          <div className="card-body">
            <div className="dash-widget-header">
              <div>
                <h3 className="card_widget_header">{data?.total_voters}</h3>
                <h6 className="text-muted">Total Voters</h6>{" "}
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                {" "}
                <span className="opacity-7 text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewbox="0 0 24 24"
                    fill="none"
                    stroke="#009688"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-user-plus"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <line x1="20" y1="8" x2="20" y2="14"></line>
                    <line x1="23" y1="11" x2="17" y2="11"></line>
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card board1 fill padding-card">
          <div className="card-body">
            <div className="dash-widget-header">
              <div>
                <h3 className="card_widget_header">{data?.total_candidates}</h3>
                <h6 className="text-muted">Total Candidates</h6>{" "}
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                {" "}
                <span className="opacity-7 text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewbox="0 0 24 24"
                    fill="none"
                    stroke="#009688"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-dollar-sign"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card board1 fill padding-card">
          <div className="card-body">
            <div className="dash-widget-header">
              <div>
                <h3 className="card_widget_header">{data?.total_voted}</h3>
                <h6 className="text-muted">Total Vote Cast</h6>{" "}
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                {" "}
                <span className="opacity-7 text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewbox="0 0 24 24"
                    fill="none"
                    stroke="#009688"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-file-plus"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="12" y1="18" x2="12" y2="12"></line>
                    <line x1="9" y1="15" x2="15" y2="15"></line>
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card board1 fill padding-card">
          <div className="card-body">
            <div className="dash-widget-header">
              <div>
                <h3 className="card_widget_header">{data?.percentagevoted}</h3>
                <h6 className="text-muted">Percentage Vote Cast</h6>{" "}
              </div>
              <div className="ml-auto mt-md-3 mt-lg-0">
                {" "}
                <span className="opacity-7 text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewbox="0 0 24 24"
                    fill="none"
                    stroke="#009688"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-globe"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
