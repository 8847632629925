/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "../../context/ApiContext";

function Positions() {
  const navigate = useNavigate();
  const { GetRequest } = useApiContext();
  const [polls, setPolls] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(0);

  useEffect(() => {
    async function getPolls() {
      const response = await GetRequest("/api/poll/all");
      if (response?.status === 200) {
        setPolls(response?.data);
      }
    }
    getPolls();
  }, []);

  useEffect(() => {
    async function getPolls() {
      const response = await GetRequest(
        `/api/position/poll/${selectedPoll}/all`
      );
      if (response?.status === 200) {
        setPositions(response?.data);
      }
    }
    if (selectedPoll !== 0) {
      getPolls();
    }
  }, [selectedPoll]);

  return (
    <div className="page-wrapper" style={{ minHeight: "667px" }}>
      <div className="container center-items col-lg-12">
        <div className="card card-shadow">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <h3>Manage Positons</h3>
              <div className="w-25">
                <select
                  className="form-control"
                  onChange={(e) => setSelectedPoll(e.target.value)}
                >
                  <option value={0}>Select Poll</option>
                  {polls?.map((poll, index) => (
                    <option key={index} value={poll?.id}>
                      {poll?.title}
                    </option>
                  ))}
                </select>
              </div>
              {selectedPoll !== "0" && (
                <button
                  className="btn btn-secondary"
                  onClick={() =>
                    navigate(`/dashboard/poll/${selectedPoll}/position/create`)
                  }
                >
                  Add New Position
                </button>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="table-responive w-100">
              {selectedPoll === "0" ? (
                <div className="text-center">
                  <h4 className="mt-4">
                    Please Select Poll to Manage Positions
                  </h4>
                </div>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {positions?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-capitalize">{item?.title}</td>
                        <td>{item?.state}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Positions;
